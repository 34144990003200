import React from "react"
import Layout from "../../components/Layout"
import SubHero from "../../components/SubHero"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Seo from "../../components/Seo"

export const query = graphql`
  query BlogPostThree {
    file(relativeDirectory: { eq: "blog" }, name: { eq: "blog3" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const BlogPageThree = ({ data }) => {
  return (
    <Layout>
      <Seo
        title="The ‘Happy’ about Birthdays!"
        description="My phone’s never been so busy. It’s been busier than that of Tim
            Cook’s. It’s flooded with a plethora of texts, notifications, calls,
            missed calls, facetimes, reminders, snaps, etc."
      />
      <SubHero title={null} />
      <section className="blogpost__container">
        <div className="blogpost__meta">
          <h1>The ‘Happy’ about Birthdays!</h1>
          <p>
            Here’s me wishing my own self — A Happy Birthday. Because it really
            was a <b>Happy</b> Birthday!
          </p>
        </div>
        <div className="post">
          <Img fluid={data.file.childImageSharp.fluid} alt="" />
          <p>
            <br />
            My phone’s never been so busy. It’s been busier than that of Tim
            Cook’s. It’s flooded with a plethora of texts, notifications, calls,
            missed calls, facetimes, reminders, snaps, etc.
            <br />
            <br />
            See! That’s the thing about birthdays. All that happens — everything
            — happens with a pinch of irony in it. And here is the irony:
          </p>
          <p className="quote" style={{ lineHeight: 1.325 }}>
            “All bad things, suddenly, become all of the good things.”
          </p>
          <p>
            I mean, you don’t complain about your friends beating the shit out
            of you — yeah, birthday bombs. Rather you enjoy every hit of it — on
            your back, in your stomach, over the shoulders… and other weird
            places. But yeah, the point is —
          </p>
          <p className="quote" style={{ lineHeight: 1.325 }}>
            “You enjoy it! Don’t you?”
          </p>
          <p>
            Then there’s cake on your face, in your hair, on your clothes, on
            the floor, on the walls, &amp; throughout the entire house. But,
            unlike every other day, you don’t get pissed. Rather, you enjoy
            being sloshed in the cake frosting accompanied with squeezed
            tomatoes, eggs and a whole lot of other things. That too, at 12 in
            the night. Again, the point being — all of it does not frustrate you
            (as would do on a normal day)…
          </p>
          <p className="quote" style={{ lineHeight: 1.325 }}>
            “You enjoy the stickiness! Don’t you?”
          </p>
          <p>
            Normally, you don’t drink. Or don’t ‘drink much’. And when your
            friends insist (or compel), you just can’t say no — either ways.
            But, instead of regretting by default, you start to feel happy. Your
            head is spinning, but for the first time —
          </p>
          <p className="quote" style={{ lineHeight: 1.325 }}>
            “You fucking enjoy the buzz! Don’t you?”
          </p>
          <p>
            <b>Let me tell you what happens inside that makes it special.</b>
          </p>
          <p className="quote" style={{ lineHeight: 1.325, fontWeight: 600 }}>
            “You let loose of your ego.”
          </p>
          <p>
            You don’t care about how you look with all that cake &amp; frosting
            on your face, or in your hair. You, for the time being, stop
            thinking about what other people will think, &amp; completely forget
            that there exists some ‘fear of judgement’ sort of a thing. Every
            fibre in your body starts being you. And that is exactly when magic
            happens! Because you know —
          </p>
          <p className="quote" style={{ lineHeight: 1.325 }}>
            “It’s your day! Your HAPPY birth-day!”
          </p>
          <p>The cherry on top is that —</p>
          <p className="quote" style={{ lineHeight: 1.325 }}>
            “There’s a smiley affixed to every birthday wish you receive. And a
            bigger one supporting every thank you.”
          </p>
          <p>
            Everyone around you is so happy. And most importantly, you are
            happy! After all, seeing people happy, and especially your closed
            ones, is all that you want from life. Or atleast — from this day.
            <br />
            <br />
            Things start to get real. <br />
            <br /> This is the day when real conversations take place, real
            meets happen, real emotions vibrate, real feelings exchange. The
            word real derives a whole new meaning just for this day.
          </p>
          <p className="quote" style={{ lineHeight: 1.325 }}>
            ‘To the ‘iss-raat-ki-subah-nahi-honi-chaheye’ type of nights!”
          </p>
          <p>
            Wondering if you could live like this forever? If you could let your
            ego loose, be happy and keep everyone happy everyday?
            <br />
            <br /> I guess, no. And it should not be. Because that is the beauty
            of it. Misquoting Gwen Stacy from The Amazing Spiderman,
          </p>
          <p className="quote" style={{ lineHeight: 1.325 }}>
            “What makes a birthday valuable is that it doesn't last forever.
            What makes it precious is that it ends.”
          </p>
          <p>
            <b>
              If it were to happen everyday, then it won’t be a ‘Happy’
              Birthday.
            </b>
          </p>
          <p className="quote" style={{ lineHeight: 1.325 }}>
            See you next year!
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default BlogPageThree
